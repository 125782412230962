import React from 'react';

import GradientButton from '../components/gradient-button';

import subscribeBanner1 from '../assets/premium/subscribe-banner-1.png';
import subscribeBanner2 from '../assets/premium/subscribe-banner-2.png';
import feature1 from '../assets/premium/feature-1.png';
import feature2 from '../assets/premium/feature-2.png';
import feature3 from '../assets/premium/feature-3.png';
import feature4 from '../assets/premium/feature-4.png';
import featuredropmaps from '../assets/premium/feature-dropmaps.png';
import featuredropspots from '../assets/premium/feature-dropspots.png';

const featureList = [
    {
        header: "Tournament preparation",
        thumbnail: feature1,
        details: [
            `Kinch’s personal thoughts about the points system and how you should play`,
            `Points estimates and breakdowns of what will be required to qualify in terms of
            elims/placement during your 10 games
            several hours before`,
            `Advice on when you need to queue by for your last game`,
        ],
    },
    {
        header: "Live Points Estimates (EU/NA)",
        thumbnail: feature2,
        details: [
            `Pre-round estimates before the tournaments start`,
            `Updated estimates throughout the tournament`,
            `The live estimates had an average error of just 0.71% in Chapter 5 Season 1!`,
        ],
    },
    {
        header: "Perfect Dropmaps",
        thumbnail: featuredropmaps,
        details: [
            `Mathematically Perfect Dropmaps provided through partnership with dropmap.net`,
            `Over 100 existing locations and request any new dropspots`,
            `Each subscription month you will be able to claim 2 dropmaps`
        ],
    },
    {
        header: "Dropspot Ratings (EU/NA)",
        thumbnail: featuredropspots,
        details: [
            `We rate every Dropspot's Loot and Materials and how Contested it is`,
            `From this data we can tell you the best dropspots on the map!`,
        ],
    },
    {
        header: "Helpful Bots",
        thumbnail: feature3,
        details: [
            `Game Planner AI - gives you a plan for the rest of the tournament, including when to wait for easier lobbies, when to wkey, what placement/elims you need in each game etc`,
            `Series Points Bot - during FNCS calculate what placements you need to qualify for Heats`,
        ],
    },
    {
        header: "Personal Service",
        thumbnail: feature4,
        details: [
            `Kinch himself is active in Premium Chat`,
            `You can ask him quick questions about rules, series points, qualification points, etc.`,
        ]
    }
]

const PremiumPage = () => (
    <>
        <div className="w-full">
            <div className="text-left sm:text-center max-w-xl sm:my-14 md:my-24 mx-auto">
                <header>
                    <h1>Kinch Premium</h1>
                </header>
                <div className="text-2xl mt-8 font-medium">
                    A Professional and Personal Analytics Service
                    to help Competitive Fortnite Players<br/>
                    <span className="text-purple-600">Free 1-week trial, then $8 per month</span>
                </div>
                <div className="font-normal mt-2">
                    All services are provided through the Kinch Analytics Discord
                </div>
                <GradientButton className="my-8 h-12">
                    <a href="https://upgrade.chat/kinchanalytics" target="_blank" class="px-8">Subscribe</a>
                </GradientButton>
            </div>

            <div className="w-full mb-14 md:mb-24 flex flex-wrap justify-center">
                <div className="w-full visible sm:hidden mb-8">
                    <p className="mb-4">
                    The video below explains what is included in this service, or you can read the
                    summary text below.
                    </p>
                    <p>It is run through our Discord, which has free info channels and all the info for Premium subscribers.</p>
                </div>
                <div className="gradient-to-b-wrapper rounded-3xl relative inline-block"
                    style={{ width: 'calc(95vh / 0.5625)', maxWidth: 768 }}>
                    <div className="overflow-hidden rounded-3xl lg:max-w-screen-md block relative"
                        style={{ paddingTop: '56.25%' }}>
                        <iframe width="768" height="432" src="https://www.youtube.com/embed/54EbBz6Ewyo"
                                className="w-full h-full absolute inset-0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </div>

            {featureList.map((feature, idx) =>
                <div className="grid grid-cols-12 justify-between mb-10 md:mb-20" key={idx}>
                    <div className={`col-span-12 md:col-span-6 mt-2 md:mt-0
                        order-2 ${idx % 2 ? "md:order-3" : "md:order-1"}`
                    }>
                        <div
                            className="gradient-to-b-wrapper rounded-3xl relative">
                            <img src={feature.thumbnail} className="rounded-3xl p-px
                                w-full max-h-1/2 object-cover md:h-80 lg:h-auto
                                bg-indigo-900" alt="" />
                        </div>
                    </div>
                    <div className={`col-span-12 md:col-span-5 flex items-center flex-wrap
                        order-1 ${idx % 2 ? "md:order-1" : "md:order-3"}`
                    }>
                        <div className="w-full">
                            <div className="text-2xl w-full font-medium">{feature.header}</div>
                            <ul className="list-inside text-sm text-medium mt-6">
                                {feature.details.map((detail, idx) =>
                                    <li className="mb-4 relative pl-6" key={idx}>
                                        <span className="w-2 h-2 rounded-3xl bg-ka-cyan block mr-2 absolute top-1 mt-px left-0"></span>
                                        {detail}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-1 order-2"></div>
                </div>
            )}

            <div className="w-full relative sm:pt-8 overflow-hidden mb-10 sm:mb-20">
                <div className="w-full sm:w-auto p-px rounded-3xl subscribe-block">
                    <div className="block rounded-3xl md:p-10">
                        <div className="grid grid-cols-12 justify-between">
                            <div className="col-span-4 hidden xl:block">
                                <img className="absolute left-0 top-0" src={subscribeBanner1}/>
                            </div>
                            <div className="col-span-12 md:col-span-7 xl:col-span-4 w-full text-center">
                                <div className="text-3xl lg:text-5xl font-semibold">
                                    Subscribe Now
                                </div>
                                <div className="mt-4 mb-6">
                                    Kinch Premium is an Analytics Service provided for $8 per month.
                                </div>
                                <GradientButton className="h-12">
                                    <a href="https://upgrade.chat/kinchanalytics" target="_blank" class="px-8">Subscribe</a>
                                </GradientButton>
                            </div>
                            <div className="col-span-4 hidden md:block">
                                <img className="absolute top-0 right-0" src={subscribeBanner2}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default PremiumPage;
